<template>
  <div class="loadingBar">
    <div class="barBG">
      <div class="loader">
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--dot"></div>
        <div class="loader--text">{{ text }}...</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: ["text"],
  data() {
    return {};
  },
  computed: {
    ...mapState(["alert"]),
  },
  methods: {
    handleClose() {
      let data = {
        alertText: "",
        alertState: false,
      };
      this.$store.dispatch("SET_ALERT", data);
    },
  },
};
</script>
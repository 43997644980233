<template>
  <div>
    <Loading v-if="isLoading" text="데이터 분석중..." />
    <router-view></router-view>
  </div>
</template>
<script>
import { format } from "@/mixins/format";
import { setNavbar } from "@/mixins/setNavbar";
import { setFooter } from "@/mixins/setFooter";
import { mapState } from "vuex";
import Loading from "@/components/globalComponents/Loading";
export default {
  mixins: [format, setNavbar, setFooter],
  components: { Loading },
  mounted() {
    this.setNavbar(false);
    this.setFooter(false);
  },
  computed: {
    ...mapState(["isLoading"]),
  },
  beforeDestroy() {
    this.setNavbar(true);
    this.setFooter(true);
  },
};
</script>
